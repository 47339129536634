import { useState } from "react"
import styled from "styled-components"

import { FormControl, InputLabel, Select, TextField } from "@material-ui/core"

import { usePostCallAssistFeedback } from "src/data/callAssist/queries/callAssistQueries"
import { TCallAssistFeedbackBody } from "src/data/callAssist/types/callAssistTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

type TCallStatus = TCallAssistFeedbackBody["status"]

const eventCallStatusList: { name: string; value: TCallStatus }[] = [
  { name: "Guest complies", value: "call_completed" },
  { name: "Guest does not comply", value: "call_completed" },
  { name: "Guest no answer", value: "no_answer" },
]

export function CallAssistReportForm({
  eventId,
  onSubmitSuccess,
  onSubmitError,
  submitDisabled,
}: {
  eventId: string
  onSubmitSuccess: (value: boolean) => void
  onSubmitError?: (value: boolean) => void
  submitDisabled?: boolean
}) {
  const { t, langKeys } = useTranslate()
  const postCallAssistFeedback = usePostCallAssistFeedback({ eventId })

  // Form data
  const [callOutcome, setCallOutcome] = useState<TCallStatus | "">("")
  const [eventDetails, setsEventDetails] = useState("")
  const [dispatchHistory, setDispatchHistory] = useState("")

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        if (!callOutcome) {
          return
        }
        postCallAssistFeedback.mutate(
          {
            operator_feedback: eventDetails,
            status: callOutcome,
            dispatch_history: dispatchHistory,
          },
          {
            onSuccess: () => onSubmitSuccess(true),
            onError: () => onSubmitError?.(true),
          }
        )
      }}
    >
      <FormControl>
        <InputLabel
          htmlFor="call-outcome"
          required
          style={{ background: "white" }}
        >
          Event result
        </InputLabel>
        <Select
          id="call-outcome"
          native
          fullWidth
          required
          onChange={(e) => {
            const value = e.target.value as TCallStatus
            setCallOutcome(value)
          }}
          value={callOutcome ?? ""}
        >
          <option value="" />
          {eventCallStatusList.map((item) => {
            return (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            )
          })}
        </Select>
      </FormControl>

      <TextField
        label="Additional details"
        multiline
        required
        fullWidth
        minRows="2"
        value={eventDetails}
        inputProps={{ maxLength: 2500 }}
        onChange={(event) => setsEventDetails(event.target.value)}
        placeholder="Please summarize the conversation with the guest"
      />

      <TextField
        label={t(langKeys.placeholder_web, { text: "Dispatch history" })}
        multiline
        minRows="2"
        required
        fullWidth
        value={dispatchHistory}
        inputProps={{ maxLength: 2500 }}
        onChange={(event) => setDispatchHistory(event.target.value)}
      />

      {postCallAssistFeedback.isError && (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )}

      <MButtonLegacy
        size="large"
        loading={postCallAssistFeedback.isLoading}
        type="submit"
        fullWidth
        disabled={submitDisabled}
      >
        Submit
      </MButtonLegacy>
    </Form>
  )
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`
